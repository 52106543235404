import React, { useCallback, useContext, useEffect, useState } from 'react'
import { RendererProps } from '../types'
import {
	MegaStoreWithSubscriptions,
	ResponsiveLayoutResult,
	getComponentResponsiveLayoutCss,
	selectorObjToCss,
	toMediaQuery,
	BreakpointsVariantsToSelectorsMap,
	CompVariants,
} from '@wix/thunderbolt-catharsis'
import { BreakpointRange, BreakpointsData, Component } from '@wix/thunderbolt-becky-types'
import { BatchingStrategy } from '@wix/thunderbolt-symbols'
import _ from 'lodash'

type ComponentsCssProps = {
	rootCompId: string
	structureStore: RendererProps['structure']
	megaStore: MegaStoreWithSubscriptions
	batchingStrategy: BatchingStrategy
}
type StoresContextValue = {
	structureStore: MegaStoreWithSubscriptions
	breakpointsOrderStore: MegaStoreWithSubscriptions
	variantsStore: MegaStoreWithSubscriptions
	responsiveLayoutStore: MegaStoreWithSubscriptions
	pinnedLayerStore: MegaStoreWithSubscriptions
	batchingStrategy: BatchingStrategy
}

const StoresContext = React.createContext<StoresContextValue>({} as StoresContextValue)

const StructureComponentCss = ({
	id,
	isInRepeater = false,
	breakpointsData,
	ancestorsBreakpointsData,
}: {
	id: string
	isInRepeater: boolean
	breakpointsData: BreakpointsData | null
	ancestorsBreakpointsData: { [breakpointId: string]: BreakpointRange }
}) => {
	const {
		structureStore,
		breakpointsOrderStore,
		responsiveLayoutStore,
		variantsStore,
		pinnedLayerStore,
		batchingStrategy,
	} = useContext<StoresContextValue>(StoresContext)

	const compStructure = structureStore.getById<Component>(id)
	const breakpointsOrder = breakpointsOrderStore.getById<BreakpointsData | null>(id) || breakpointsData
	const responsiveLayout = responsiveLayoutStore.getById<ResponsiveLayoutResult>(id)
	const pinnedLayer = pinnedLayerStore.getById<BreakpointsVariantsToSelectorsMap | null>(id)
	const variants = variantsStore.getById<CompVariants>(id)

	const [, setTick] = useState(0)
	const forceUpdate = useCallback(() => setTick((tick) => tick + 1), [])
	const stores = [structureStore, breakpointsOrderStore, responsiveLayoutStore, pinnedLayerStore, variantsStore]

	// TODO: overkill
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => stores.forEach((store) => store.subscribeById(id, () => batchingStrategy.batch(forceUpdate))), [
		compStructure,
		breakpointsOrder,
		responsiveLayout,
		pinnedLayer,
		variants,
	])

	if (!compStructure) {
		return null
	}

	const breakpointsToSelectorObj = getComponentResponsiveLayoutCss(
		id,
		isInRepeater,
		responsiveLayout,
		pinnedLayer,
		variants,
		breakpointsOrder
	)

	const breakpointsOrderMap = breakpointsOrder?.values ? _.keyBy(breakpointsOrder?.values, 'id') : null
	const myBreakpointsDataMap = breakpointsOrderMap
		? { ...ancestorsBreakpointsData, ...breakpointsOrderMap }
		: ancestorsBreakpointsData

	const children = compStructure.components?.map((childId) => (
		<StructureComponentCss
			key={childId}
			id={childId}
			isInRepeater={isInRepeater || compStructure.componentType === 'Repeater'}
			breakpointsData={breakpointsOrder}
			ancestorsBreakpointsData={myBreakpointsDataMap}
		/>
	))

	const breakpoints = breakpointsOrder?.values
		? ['default', ...breakpointsOrder.values.map((b) => b.id)]
		: ['default']

	return (
		<React.Fragment>
			{breakpoints.map((breakpointId) => {
				const breakpointCss = breakpointsToSelectorObj[breakpointId]
				if (!breakpointCss) {
					return null
				}
				const currentBreakpointCss = selectorObjToCss(breakpointCss)

				return (
					<style key={breakpointId} data-comp-id={id}>
						{breakpointId === 'default'
							? currentBreakpointCss
							: `${toMediaQuery(
									myBreakpointsDataMap[breakpointId] as BreakpointRange
							  )}{${currentBreakpointCss}}`}
					</style>
				)
			})}
			{children}
		</React.Fragment>
	)
}

export const ComponentsCss = (props: ComponentsCssProps) => {
	const structureStore = props.megaStore.getChildStore('structure')
	const breakpointsOrderStore = props.megaStore.getChildStore('breakpointsOrder')
	const variantsStore = props.megaStore.getChildStore('variants')
	const responsiveLayoutStore = props.megaStore.getChildStore('responsiveLayout')
	const pinnedLayerStore = props.megaStore.getChildStore('pinnedLayer')

	const updateStore = (partialStore: any) => {
		for (const compId in partialStore) {
			structureStore.updateById(compId, partialStore[compId])
		}
	}
	updateStore(props.structureStore.getEntireStore())
	props.structureStore.subscribeToChanges(updateStore)

	const storesContextValue: StoresContextValue = {
		structureStore,
		breakpointsOrderStore,
		variantsStore,
		responsiveLayoutStore,
		pinnedLayerStore,
		batchingStrategy: props.batchingStrategy,
	}

	return (
		<StoresContext.Provider value={storesContextValue}>
			<StructureComponentCss
				id={props.rootCompId}
				isInRepeater={false}
				breakpointsData={null}
				ancestorsBreakpointsData={{}}
			/>
		</StoresContext.Provider>
	)
}
